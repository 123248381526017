import React, { useState, useEffect, useMemo } from 'react';
import axios from 'axios';

import logo from './logo.png';
import twitter from './twitter.png';

import './App.css';
import './Profile.css';
import WalletComponent from './Components/WalletComp';

import {
  ConnectionProvider,
  WalletProvider
} from '@solana/wallet-adapter-react';
import {
  WalletAdapterNetwork
} from '@solana/wallet-adapter-base';
import {
  WalletModalProvider
} from '@solana/wallet-adapter-react-ui';
import { PhantomWalletAdapter, PhantomWalletName } from '@solana/wallet-adapter-phantom';
import '@solana/wallet-adapter-react-ui/styles.css';


import { clusterApiUrl } from "@solana/web3.js"

const App = () => {

  axios.defaults.withCredentials = true;

  const [twitterUsername, setTwitterUsername] = useState('');
  const [twitterName, setTwitterName] = useState('');
  const [twitterPFP, setTwitterPFP] = useState('');
  const [twitterBanner, setTwitterBanner] = useState('');

  const checkConnected = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/user`, { withCredentials: true }
      );
      const data = response.data;
      if (response.data.username) {
        setTwitterBanner(response.data.profileBanner)
        setTwitterUsername(response.data.username)
        setTwitterName(response.data.name)
        setTwitterPFP(response.data.profilePictureUrl)

      } else {
        // NotificationManager.error("Error connecting.", "Failed", 5000)
      }
    } catch (error) {
      console.error('Failed to make API call:', error);
    }
  };

  const usersTwitter = async () => {
    try {
      window.location.href = `https://twitter.com/${twitterUsername}`;
    } catch (error) {
      console.error('Failed to fetch authorization URL:', error);
    }
  };

  const OAuthTwitter = async () => {
    try {
      window.location.href = `${process.env.REACT_APP_API_URL}/auth/twitter`;
    } catch (error) {
      console.error('Failed to fetch authorization URL:', error);
    }
  };

  const logout = async () => {
    try {
        const response = await axios.get(
            `${process.env.REACT_APP_API_URL}/logout`, { withCredentials: true }
        );
        if (response.data.result === "success") {
            window.location.href = "/profile";
        } else {
            // NotificationManager.error("Couldn't logout.", "Error", 5000)
        }
    } catch (error) {
        console.error('Failed to fetch authorization URL:', error);
    }
};

  const network = WalletAdapterNetwork.Mainnet;

  const endpoint = useMemo(() => clusterApiUrl(network), [network])

  const wallets = useMemo(
    () => [
      {
        name: PhantomWalletName,
        wallet: PhantomWalletAdapter, //
      },
    ],
    [network]
  )
  

  useEffect(() => {
    checkConnected();
  }, []);

  return (
    <div className="App">
      <div className='header'>
        <div className='nav'>
          <div className='logo'>
            <a href="https://infinitylabsnft.com" target="_blank">
              <img src={logo} />
            </a>
          </div>
          <div className='nav-dir'>
            <div className='nav-dir-a'>
              <a href="/home"> Home </a>
            </div>
            <div className='nav-dir-a'>
              <a href="/find"> Find </a>
            </div>
            <div className='nav-dir-a'>
              <a href="/list"> List </a>
            </div>
            <div className='nav-dir-a nav-active'>
              <a href="/profile"> Profile </a>
            </div>
          </div>
        </div>
      </div>

      <div className='main'>
        <div className='profile'>
          {twitterUsername ? (
            <div className="profile-inner">
              <img className='profile-banner' src={twitterBanner} />
              <img className='profile-img' src={twitterPFP} />
              <div className='profile-name'>
                {twitterName}
              </div>
              <div className='profile-username'>
                @{twitterUsername}
              </div>
              <div>
              <a className='twitter-icon' onClick={logout}>
                <img className='twitter-icon-img' src={twitter} /> Disconnect
              </a>
              </div>
              <div className='wallet-connect'>
                <ConnectionProvider endpoint={endpoint}>
                  <WalletProvider wallets={wallets} autoConnect>
                    <WalletModalProvider>
                      <WalletComponent />
                    </WalletModalProvider>
                  </WalletProvider>
                </ConnectionProvider>
              </div>
            </div>
          ) : (
            <a className='connect-twitter-btn' onClick={OAuthTwitter}><img className='connect-twitter-img' src={twitter} /><div>Connect Twitter</div></a>
          )}

        </div>

      </div>

    </div>
  );
}

export default App;

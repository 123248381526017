import React, { useContext } from 'react';

import {
    WalletContext
} from '@solana/wallet-adapter-react';


import {
    WalletMultiButton
} from '@solana/wallet-adapter-react-ui';

const WalletComponent = () => {
    const { wallet } = useContext(WalletContext);
    return <WalletMultiButton />;
};

export default WalletComponent;
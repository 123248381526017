import React, { useState, useEffect, useMemo } from 'react';
import logo from './logo.png';
import './App.css';
import WalletComponent from './Components/WalletComp'
import {
  ConnectionProvider,
  WalletProvider
} from '@solana/wallet-adapter-react';
import {
  WalletAdapterNetwork
} from '@solana/wallet-adapter-base';
import {
  WalletModalProvider
} from '@solana/wallet-adapter-react-ui';
import { PhantomWalletAdapter, PhantomWalletName } from '@solana/wallet-adapter-phantom';
import '@solana/wallet-adapter-react-ui/styles.css';


import { clusterApiUrl } from "@solana/web3.js"

const App = () => {

  const network = WalletAdapterNetwork.Mainnet;

  const endpoint = useMemo(() => clusterApiUrl(network), [network])

  const wallets = useMemo(
    () => [
      {
        name: PhantomWalletName,
        wallet: PhantomWalletAdapter, //
      },
    ],
    [network]
  )

  useEffect(() => {

  }, []);

  return (
    <div className="App">
      <div className='header'>
        <div className='nav'>
          <div className='logo'>
            <a href="https://infinitylabsnft.com" target="_blank">
              <img src={logo} />
            </a>
          </div>
          <div className='nav-dir'>
            <div className='nav-dir-a'>
                <a href="/home"> Home </a>
            </div>
            <div className='nav-dir-a'>
                <a href="/find"> Find </a>
            </div>
            <div className='nav-dir-a nav-active'>
                <a href="/list"> List </a>
            </div>
            <div className='nav-dir-a'>
                <a href="/profile"> Profile </a>
            </div>
          </div>
        </div>


      </div>

    </div>
  );
}

export default App;

import React, { useState, useEffect, useMemo } from 'react';
import logo from './logo.png';
import './App.css';
import './Home.css';

import WalletComponent from './Components/WalletComp'
import {
  ConnectionProvider,
  WalletProvider
} from '@solana/wallet-adapter-react';
import {
  WalletAdapterNetwork
} from '@solana/wallet-adapter-base';
import {
  WalletModalProvider
} from '@solana/wallet-adapter-react-ui';
import { PhantomWalletAdapter, PhantomWalletName } from '@solana/wallet-adapter-phantom';
import '@solana/wallet-adapter-react-ui/styles.css';


import { clusterApiUrl } from "@solana/web3.js"

const App = () => {

  const network = WalletAdapterNetwork.Mainnet;

  const endpoint = useMemo(() => clusterApiUrl(network), [network])

  const wallets = useMemo(
    () => [
      {
        name: PhantomWalletName,
        wallet: PhantomWalletAdapter, //
      },
    ],
    [network]
  )

  useEffect(() => {

  }, []);

  return (
    <div className="App">
      <div className='header'>
        <div className='nav'>
          <div className='logo'>
            <a href="https://infinitylabsnft.com" target="_blank">
              <img src={logo} />
            </a>
          </div>
          <div className='nav-dir'>
            <div className='nav-dir-a nav-active'>
              <a href="/home"> Home </a>
            </div>
            <div className='nav-dir-a'>
              <a href="/find"> Find </a>
            </div>
            <div className='nav-dir-a'>
              <a href="/list"> List </a>
            </div>
            <div className='nav-dir-a'>
              <a href="/profile"> Profile </a>
            </div>
          </div>
        </div>
      </div>
      <div className='home-main'>
        <div className='home'>
          <text className='home-title'> Connect with Infinity Labs Holders</text>
          <text className='home-subtitle'> Join using your Twitter & Solana Wallet.</text>

        </div>
        <div className='home-directions'>
          <div className='home-dir-1'>
            <a href="/profile">Join</a> with your Solana wallet & Twitter.
          </div>
          <div className='pointer-box'><div className='pointer' /></div>
          <div className='home-dir-2'>
            <a href="/find">Find</a> what scenes you're trying to collect.
          </div>
          <div className='pointer-box'><div className='pointer' /></div>
          <div className='home-dir-3'>
            <a href="/list">List</a> what scenes you're trying to sell.
          </div>
          <div className='pointer-box'><div className='pointer' /></div>
          <div className='home-dir-4'>
            Connect with other holders.
          </div>
        </div>
        <div className='security-section'>
          <text className='security-title'>Security & Safety</text>
          <div className='security-box'>
          <div className='security-subtitle-box'>
            <text className='security-subtitle'>Twitter Connection</text><br />
            </div>
            <text className='security-info'> When connecting to our app, only Read Access is required. This means we can read your public data like you Twitter name, username, profile picture and profile banner. This information is just used so people can message you if they want to buy a scene from you. It also allows you to send messages to people you want to buy scenes from. </text>
          </div>
          <div className='pointer-box'><div className='pointer' /></div>
          <div className='security-box'>
          <div className='security-subtitle-box'>
            <text className='security-subtitle'>Wallet Connection</text><br />
            </div>
            <text className='security-info'> When connecting your wallet to this site, you will never have to confirm a transaction. This is because all the sales go on outside of this website. You don't even have to sign a login transaction as all we need to see is that you have a wallet. The only button you have to press is the wallet connection, then 'Connect' in your Phantom or other Solana wallet. Please note, if this website ever tries to make you confirm a transaction then you are on the wrong website and are likely being scammed.</text>
          </div>
          <div className='pointer-box'><div className='pointer' /></div>
          <div className='security-box'>
            <div className='security-subtitle-box'>
              <text className='security-subtitle'>My Data</text><br />
            </div>
            
            <text className='security-info'> None of your data is stored anywhere other than our databases. We do not sell or share your information. The only information we store is your Twitter details, stated in 'Twitter Connection', your public wallet address & your scenes that you want to buy or sell. </text>
          </div>
        </div>
        <div className='footer-section'>
          <div>
            <text className='security-subtitle'>Made by <div className='author'><a className='author-link' href='https://twitter.com/homagetech' target="_blank"> Clearzey </a></div></text>
          </div>
        </div>
      </div>

    </div>
  );
}

export default App;

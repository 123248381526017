import React, { useState, useEffect, useMemo, useCallback } from 'react';
import logo from './logo.png';
import search from './search.png';
import axios from 'axios';
import './App.css';
import './Find.css';

import WalletComponent from './Components/WalletComp'
import {
  ConnectionProvider,
  WalletProvider
} from '@solana/wallet-adapter-react';
import {
  WalletAdapterNetwork
} from '@solana/wallet-adapter-base';
import {
  WalletModalProvider
} from '@solana/wallet-adapter-react-ui';
import { PhantomWalletAdapter, PhantomWalletName } from '@solana/wallet-adapter-phantom';
import '@solana/wallet-adapter-react-ui/styles.css';

// import { Metaplex, keypairIdentity } from "@metaplex-foundation/js";
import { Connection, Keypair, PublicKey } from "@solana/web3.js";


import { clusterApiUrl } from "@solana/web3.js"

const App = () => {

  const network = WalletAdapterNetwork.Mainnet;

  const endpoint = useMemo(() => clusterApiUrl(network), [network])

  const wallets = useMemo(
    () => [
      {
        name: PhantomWalletName,
        wallet: PhantomWalletAdapter, //
      },
    ],
    [network]
  )


  const checkNFTs = async () => {
    // const MY_WALLET_ADDRESS = "8M4dSiEzq7rJkvTSWD8875ijY4zES7fpxLhMNyhFw1ag";
    // const connection = new Connection(clusterApiUrl("mainnet-beta"), "confirmed");
  
    // const accounts = await connection.getParsedProgramAccounts(
    //   new PublicKey("TokenkegQfeZyiNwAJbNbGKPFXCWuBvf9Ss623VQ5DA"),
    //   {
    //     filters: [
    //       {
    //         dataSize: 165, // number of bytes
    //       },
    //       {
    //         memcmp: {
    //           offset: 32, // number of bytes
    //           bytes: MY_WALLET_ADDRESS, // base58 encoded string
    //         },
    //       },
    //     ],
    //   }
    // );
  
    // console.log(
    //   `Found ${accounts.length} token account(s) for wallet ${MY_WALLET_ADDRESS}: `
    // )
    
// const url = 'https://api.mainnet-beta.solana.co'; // Replace with your API URL

// const requestData = {
//   jsonrpc: '2.0',
//   id: 1,
//   method: 'getTokenAccountsByOwner',
//   params: [
//     '4Qkev8aNZcqFNSRhQzwyLMFSsi94jHqE8WNVTJzTP99F',
//     {
//       mint: '3wyAj7Rt1TWVPZVteFJPLa26JmLvdb1CAKEFZm3NY75E'
//     },
//     {
//       encoding: 'jsonParsed'
//     }
//   ]
// };

// axios.post(url, requestData, {
//   headers: {
//     'Content-Type': 'application/json'
//   }
// })
//   .then(response => {
//     console.log('Response:', response.data);
//   })
//   .catch(error => {
//     console.error('Error:', error);
//   });
// const connection = new Connection(clusterApiUrl("mainnet-beta"), "confirmed");
// const keypair = Keypair.generate();

// const metaplex = new Metaplex(connection);
// metaplex.use(keypairIdentity(keypair));

// const owner = new PublicKey("2R4bHmSBHkHAskerTHE6GE1Fxbn31kaD5gHqpsPySVd7");
// const allNFTs = await metaplex.nfts().findAllByOwner({ owner });

// console.log(allNFTs);
  };
    

  useEffect(() => {
    checkNFTs();
  }, []);

  return (
    <div className="App">
      <div className='header'>
        <div className='nav'>
          <div className='logo'>
            <a href="https://infinitylabsnft.com" target="_blank">
              <img src={logo} />
            </a>
          </div>
          <div className='nav-dir'>
            <div className='nav-dir-a'>
              <a href="/home"> Home </a>
            </div>
            <div className='nav-dir-a nav-active'>
              <a href="/find"> Find </a>
            </div>
            <div className='nav-dir-a'>
              <a href="/list"> List </a>
            </div>
            <div className='nav-dir-a'>
              <a href="/profile"> Profile </a>
            </div>
          </div>
        </div>
        {/* <div className='main'>
          <div className='find'>
            <div className='find-box'>
              <input className='find-search' placeholder='Search for a scene!' />
              <button className='find-search-btn'><img src={search} /></button>
            </div>
          </div>
          <div className='results'>

          </div>
        </div> */}


      </div>

    </div>
  );
}

export default App;
